import DetailViewContext from '@core/components/DetailViewContext';
import RecordListView from '@core/components/ListView';
import ProtectedRoute from '@core/components/ProtectedRoute';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';
import PaymentGatewayDetailViewRouter from './PaymentGatewayDetailViewRouter';

const { IDENTITY_MODULE, BILLING_MODULE, NOTIFICATION_MODULE, AUDIT_MODULE } =
  SchemaModuleTypeEnums;

const PaymentGatewayRoutes = (): ReactNode[] => [
  /* NOTIFICATION MODULE */
  <Route path="/NotificationModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/${NOTIFICATION_MODULE}/:entityName`}
        moduleName={NOTIFICATION_MODULE}
        component={<RecordListView moduleName={NOTIFICATION_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${NOTIFICATION_MODULE}/:entityName/:recordId`}
        moduleName={NOTIFICATION_MODULE}
        component={
          <DetailViewContext moduleName={NOTIFICATION_MODULE}>
            <PaymentGatewayDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* AUDIT MODULE */
  <Route path={`/${AUDIT_MODULE}`}>
    <Switch>
      <ProtectedRoute
        exact
        path={`/${AUDIT_MODULE}/:entityName`}
        moduleName={AUDIT_MODULE}
        component={<RecordListView moduleName={AUDIT_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${AUDIT_MODULE}/:entityName/:recordId`}
        moduleName={AUDIT_MODULE}
        entityName="ActivityLog"
        component={
          <DetailViewContext moduleName={AUDIT_MODULE} entityName={'ActivityLog'}>
            <PaymentGatewayDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* BILLING MODULE */
  <Route path={`/${BILLING_MODULE}`}>
    <Switch>
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/:entityName`}
        moduleName={BILLING_MODULE}
        component={<RecordListView moduleName={BILLING_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/:entityName/:recordId`}
        moduleName={BILLING_MODULE}
        component={
          <DetailViewContext moduleName={BILLING_MODULE}>
            <PaymentGatewayDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* IDENTITY MODULE */
  <Route path={`/${IDENTITY_MODULE}`}>
    <Switch>
      <ProtectedRoute
        exact
        path={`/${IDENTITY_MODULE}/:entityName`}
        moduleName={IDENTITY_MODULE}
        component={<RecordListView moduleName={IDENTITY_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${IDENTITY_MODULE}/:entityName/:recordId`}
        moduleName={IDENTITY_MODULE}
        component={
          <DetailViewContext moduleName={IDENTITY_MODULE}>
            <PaymentGatewayDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,
];

export default PaymentGatewayRoutes;
