import { NonIdealState, NonIdealStateIconSize, Spinner } from '@blueprintjs/core';
import { useOdinSearchActions } from '@core/modules/SupportModule/views/SupportDashboard/views/CaseManagementCaseListView/context/provider';
import { Col, Row } from 'antd';
import { FC, ReactNode } from 'react';
import './styles.scss';

export type TSort = {
  field: string;
  order: 'asc' | 'desc';
};

type TColumn = {
  key: string;
  title: string;
  width: number;
  filterable?: boolean;
  searchable?: boolean;
  sortable?: boolean;
  hideDivider?: boolean;
  customElement?: ReactNode;
  align?: 'left' | 'center' | 'right';
  sortOrder?: TSort['order'];
  ellipsize?: boolean;
};

interface Props {
  data: any[];
  columns: TColumn[];
  height?: string;
  minWidth?: number | string;
  isLoading?: boolean;
  isRefreshing?: boolean;
  showEmpty?: boolean;
  emptyTitle?: string;
  emptyDescription?: string;
  small?: boolean;
}

const getSortOrderIcon = (sortOrder: TColumn['sortOrder']) => {
  if (!sortOrder) return 'bi-list';
  return sortOrder === 'asc' ? 'bi-sort-down' : 'bi-sort-up';
};

const getNextSortOrder = (sortOrder: TSort['order']): TSort['order'] => {
  return sortOrder === 'asc' ? 'desc' : 'asc';
};

const OdinTable: FC<Props> = (props: Props) => {
  const {
    data,
    columns,
    height,
    isRefreshing,
    isLoading,
    minWidth,
    showEmpty,
    emptyDescription,
    emptyTitle,
    small = false,
  } = props;
  const { setSort } = useOdinSearchActions();

  const handleSortClick = (column: TColumn) => {
    if (!column.sortable) return;

    const nextSortOrder = getNextSortOrder(column.sortOrder || 'desc');
    setSort({
      field: column.key,
      order: nextSortOrder,
    });
  };

  return (
    <Row className="odinTable" style={{ opacity: isRefreshing ? 0.3 : 1, minWidth: minWidth }}>
      {isLoading && (
        <Col span={24} style={{ height: height || '100%' }}>
          <Row justify="center" align="middle" style={{ height: height || '100%' }}>
            <Col>
              <Spinner />
            </Col>
          </Row>
        </Col>
      )}

      {!isLoading && (
        <Col span={24} style={{ width: 0, padding: 1, overflowY: 'auto' }}>
          {/* Table Header */}
          <Row
            align="middle"
            style={{
              background: '#FAFAFA',
              border: '1px solid #EDEDED',
              padding: '0px 10px',
              borderRadius: '8px 8px 0 0',
            }}
          >
            {/*<Col span={1}>*/}
            {/*  <Checkbox style={{ margin: 0 }} />*/}
            {/*</Col>*/}
            {columns.map((column: TColumn, index: number) => (
              <Col
                key={'ColumnCol1' + index}
                span={column.width}
                style={{
                  height: '100%',
                  padding: small ? '2px 2px' : '8px 2px',
                }}
              >
                <Row
                  key={'ColumnRow1' + index}
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: `0 ${small ? 2 : 12}px`,
                    borderRight: column.hideDivider ? 'none' : '1px solid #D5D5D5',
                  }}
                >
                  {column.customElement && (
                    <Col key={'ColumnCol2' + index}>{column.customElement}</Col>
                  )}

                  {column.title && !column.customElement && (
                    <Col
                      key={'ColumnCol3' + index}
                      span={
                        column.align && !(column.filterable || column.searchable || column.sortable)
                          ? 24
                          : undefined
                      }
                      style={{ textAlign: column.align || 'left' }}
                    >
                      <span key={'ColumnSpan1' + index} style={{ fontWeight: 600 }}>
                        {column.title}
                      </span>
                    </Col>
                  )}

                  {column.filterable && (
                    <Col key={'ColumnCol4' + index}>
                      <i
                        key={'ColumnIcon1' + index}
                        className="bi bi-funnel"
                        style={{ color: '#646464' }}
                      />
                    </Col>
                  )}

                  {column.searchable && (
                    <Col key={'ColumnCol5' + index}>
                      <i
                        key={'ColumnIcon2' + index}
                        className="bi bi-search"
                        style={{ color: '#646464' }}
                      />
                    </Col>
                  )}

                  {column.sortable && (
                    <Col key={'ColumnCol6' + index}>
                      <i
                        key={'ColumnIcon3' + index}
                        className={`bi ${getSortOrderIcon(column.sortOrder)}`}
                        style={{ color: '#646464' }}
                        onClick={() => handleSortClick(column)}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            ))}
          </Row>
          {/*  Table Body */}
          <Row
            align="middle"
            style={{
              backgroundColor: 'white',
              alignContent: 'flex-start',
              overflowY: 'auto',
              border: '1px solid #EDEDED',
              borderTop: 0,
              borderRadius: '0 0 8px 8px',
              height: height || '100%',
            }}
          >
            {showEmpty && data.length === 0 && (
              <Col span={24} style={{ padding: '15px 0' }}>
                <NonIdealState
                  icon={'search'}
                  iconSize={24 as NonIdealStateIconSize}
                  title={emptyTitle}
                  description={emptyDescription}
                />
              </Col>
            )}

            {/* Map Data as Col */}
            {data.length > 0 &&
              data.map((dataRow: any, index: number) => {
                return (
                  <Col span={24} key={`Col1${dataRow.key}`}>
                    <Row
                      key={`Row2${dataRow.key}`}
                      className="dataRow"
                      align="top"
                      style={{
                        borderBottom:
                          data.length === index + 1 && data.length > 1
                            ? 'none'
                            : '1px solid #F2F2F2',
                      }}
                    >
                      {/*<Col span={1}>*/}
                      {/*  <Checkbox style={{ margin: 0 }} />*/}
                      {/*</Col>*/}
                      {/* Map Col for each data object item, use column span from props.columns */}
                      {Object.keys(dataRow).map((key: string, index: number) => {
                        if (key !== 'key' && key !== 'extraBottomRow') {
                          return (
                            <Col
                              key={`Col2${key}`}
                              style={{
                                textAlign: columns[index - 1]?.align || 'left',
                                overflowX: columns[index - 1].ellipsize ? 'hidden' : 'initial',
                                textOverflow: columns[index - 1].ellipsize ? 'ellipsis' : 'initial',
                              }}
                              span={columns[index - 1]?.width}
                            >
                              {dataRow[key]}
                            </Col>
                          );
                        } else if (key === 'extraBottomRow') {
                          return (
                            <Col key={`Col3${key}`} span={24}>
                              {dataRow[key]}
                            </Col>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </Row>
                  </Col>
                );
              })}
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default OdinTable;
