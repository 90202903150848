import { OrganizationUserGroupEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/group/organization.user.group.entity';
import { OrganizationUserEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/organization.user.entity';
import { OrganizationUserRbacPermissionEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/rbac/permission/organization.user.rbac.permission.entity';
import { OrganizationUserRbacRoleEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/rbac/role/organization.user.rbac.role.entity';
import { OrganizationUserTokenEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/token/organization.user.token.entity';
import { store } from '@redux/configureStore';

export const isNetomniaUser = (): boolean => {
  const state = store.getState();
  return state?.userReducer?.user?.organization?.name === 'Netomnia Limited';
};

export const isYouFibreUser = (): boolean => {
  const state = store.getState();
  return state?.userReducer?.user?.organization?.name === 'YouFibre';
};

// Remove the Payment Gateway Org once we fix the existing users still
// bearing the old name.
export const isPaymentGatewayUser = (): boolean => {
  const state = store.getState();
  return (
    state?.userReducer?.user?.organization?.name === 'Payment Gateway Org' ||
    state?.userReducer?.user?.organization?.name === 'Substantial Group Payment Services'
  );
};

export const getUserNameAndEmailAddress = (userReducer: any) => {
  let firstName,
    lastName,
    emailAddress = '-';

  if (userReducer) {
    if (userReducer?.user?.firstname) firstName = userReducer?.user?.firstname;
    if (userReducer?.user?.lastname) lastName = userReducer?.user?.lastname;
    if (userReducer?.user?.email) emailAddress = userReducer?.user?.email;
  }

  return {
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
  };
};

export const getUserFromShortListByUserId = (
  shortList: { [key: string]: OrganizationUserEntity },
  userId: string | null | undefined,
): OrganizationUserEntity | undefined => {
  return userId && shortList ? shortList[userId] : undefined;
};

export const getRoleFromShortListByUserId = (
  shortList: { [key: string]: OrganizationUserRbacRoleEntity },
  roleId: string | null | undefined,
): OrganizationUserRbacRoleEntity | undefined => {
  return roleId && shortList ? shortList[roleId] : undefined;
};

export const getPermissionFromShortListByPermissionId = (
  shortList: { [key: string]: OrganizationUserRbacPermissionEntity },
  permissionId: string | null | undefined,
): OrganizationUserRbacPermissionEntity | undefined => {
  return permissionId && shortList ? shortList[permissionId] : undefined;
};

export const getGroupFromShortListByGroupId = (
  shortList: { [key: string]: OrganizationUserGroupEntity },
  groupId: string | null | undefined,
): OrganizationUserGroupEntity | undefined => {
  return groupId && shortList ? shortList[groupId] : undefined;
};

export const getTokenFromShortListByTokenId = (
  shortList: { [key: string]: OrganizationUserTokenEntity },
  tokenId: string | null | undefined,
): OrganizationUserTokenEntity | undefined => {
  return tokenId && shortList ? shortList[tokenId] : undefined;
};

export const getEnvironmentName = (
  window: any,
): 'localhost' | 'sandbox' | 'youfibre' | 'netomnia' | undefined => {
  if (window) {
    // Localhost
    if (window.location.hostname === 'localhost') {
      return 'localhost';
    }
    // Sandbox
    else if (window.location.hostname.indexOf('sandbox') > -1) {
      return 'sandbox';
    }
    // YouFibre
    else if (window.location.hostname.indexOf('youfibre') > -1) {
      return 'youfibre';
    }
    // Netomnia
    else if (window.location.hostname.indexOf('netomnia') > -1) {
      return 'netomnia';
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export function parseUserRoles(authUser: OrganizationUserEntity) {
  let roles = [];
  let permissions = [];

  for (const role of authUser.roles!) {
    roles.push(role.name);

    if (!!role.permissions) {
      for (const permission of role.permissions) {
        permissions.push(permission.name);
      }
    }
  }
  return { roles, permissions };
}

export const validateEmail = (email: string | undefined) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
