import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { errorNotification } from '../../../../../redux/stores/notification/reducers';
import {
  closeSharedForm,
  updateSharedFormInput,
} from '../../../../../redux/stores/sharedForm/actions';
import renderFormField from '../FormFields';

interface Props {
  formReducer: any;
  formUUID: string;
  closeForm: any;
  updateForm: any;
  notifyError: any;
  onSubmitEvent: any;
  externalRef?: FormInstance; // Pass external form ref, if you need direct form control from parent component
}

interface State {
  isLoading: boolean;
}

export interface FormReducerSubmitEvt {
  id?: string | number;
  data: { [key: string]: any };
}

class FormModal extends React.Component<Props, State> {
  formRef: any = this.props.externalRef || React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { formReducer } = this.props;
    if (
      prevProps.formReducer.showModal !== this.props.formReducer.showModal &&
      formReducer.formUUID === this.props.formUUID
    ) {
      formReducer.formFields.forEach((element: any) => {
        this.formRef.current?.setFieldsValue({
          [element.property]: element.value,
        });
      });
    }
  }

  handleSubmit = async () => {
    const { notifyError, formReducer, onSubmitEvent } = this.props;
    this.setState({
      isLoading: true,
    });
    try {
      if (!!this.formRef.current) {
        await this.formRef.current.validateFields();
        const formErrors = this.formRef.current ? this.formRef.current.getFieldsError() : [];
        const hasErrors = formErrors.filter(({ errors }: any) => errors.length).length > 0;

        if (Object.keys(formReducer.saveData).length === 0) {
          this.setState({ isLoading: false });
          return notifyError({
            message: 'no modified form values',
            validation: null,
            data: null,
          });
        } else if (hasErrors) {
          this.setState({ isLoading: false });
          return notifyError({
            message: 'form has errors, fix them and resubmit',
            validation: null,
            data: null,
          });
        } else {
          onSubmitEvent({
            id: this.getIdFromFormFilelds(formReducer.formFields),
            data: this.updateSaveData(this.formRef.current.getFieldsValue(), formReducer.saveData),
            title: formReducer.title,
          });

          this.closeModal();
        }
      }
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.error(e);
    }
  };

  closeModal() {
    const { closeForm } = this.props;
    this.formRef.current?.resetFields();
    this.setState({
      isLoading: false,
    });
    closeForm();
  }

  updateSaveData(formData: any, saveData: any) {
    for (let data in saveData) {
      if (saveData[data] === undefined) {
        formData[data] = null;
      } else {
        formData[data] = saveData[data];
      }
    }
    return formData;
  }

  getIdFromFormFilelds = (data: any) => {
    return data.find((elem: any) => elem.property === 'id')?.value;
  };

  constructFormFields = (data: any) => {
    const { formReducer } = this.props;

    const field = {
      label: data.label,
      description: data.description,
      property: data.property,
      type: data.type,
      isRequired: data.isRequired,
      getIsRequired: data.getIsRequired
        ? () => {
            return data.getIsRequired(formReducer.saveData);
          }
        : undefined,
      message: data.message,
      isHidden: data.isHidden,
      value: data.value,
      initialValue: data.initialValue,
      options: data.options,
      getOptions: data.getOptions
        ? () => {
            return data.getOptions(formReducer.saveData);
          }
        : undefined,
      isDisabled: data.isDisabled,
      customValidation: data.customValidation,
      customValidationCondition: data.customValidationCondition,
      customValidationMessage: data.customValidationMessage,
      allowClear: data.allowClear,
      className: data.className,
      handleInputChange: this.handleInputChange,
      allowSearch: data.allowSearch,
      allowSort: data.allowSort,
      allowMultiple: !!data.allowMultiple,
    };
    if (data.render) {
      return data.render(field, formReducer.saveData, this.formRef);
    } else if (!data.isHidden) {
      return renderFormField(field);
    } else {
    }
  };

  handleInputChange = (params: any) => {
    const { updateForm } = this.props;
    updateForm({
      property: params.property,
      value: params.value,
    });
  };

  renderForm() {
    const { formReducer } = this.props;
    const { columns } = formReducer;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const formData = formReducer.formFields;

    return (
      <Form
        {...layout}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        ref={this.formRef}
        autoComplete="off"
        key={this.props.formReducer?.title}
        name={this.props.formReducer?.title}
      >
        <Row gutter={12}>
          {formData?.map((data: any) => {
            if (!data.isHidden) {
              return (
                <Col
                  span={columns === 2 ? 12 : 24}
                  className={data.property === 'id' ? 'hidden-col' : ''}
                >
                  {this.constructFormFields(data)}
                </Col>
              );
            }
          })}
        </Row>
      </Form>
    );
  }

  render() {
    const { formReducer } = this.props;
    const { columns } = formReducer;

    return (
      <Dialog
        usePortal={true}
        key={this.props.formUUID + JSON.stringify(formReducer?.isCreateReq)}
        style={{ width: isMobile ? '95%' : columns === 2 ? '50%' : '35%' }}
        title={formReducer.title}
        isOpen={formReducer.showModal && formReducer.formUUID === this.props.formUUID}
        canEscapeKeyClose={!this.state.isLoading}
        canOutsideClickClose={!this.state.isLoading}
        onClose={() => this.closeModal()}
      >
        <DialogBody>{this.renderForm()}</DialogBody>
        <DialogFooter
          actions={
            <>
              <Button
                text="Cancel"
                onClick={() => this.closeModal()}
                disabled={this.state.isLoading}
              />
              <Button
                text="OK"
                intent="primary"
                onClick={() => this.handleSubmit()}
                loading={this.state.isLoading}
              />
            </>
          }
        />
      </Dialog>
    );
  }
}

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  closeForm: () => dispatch(closeSharedForm()),
  updateForm: (params: any) => dispatch(updateSharedFormInput(params)),
  notifyError: (params: any) => dispatch(errorNotification(params)),
});

export default connect(mapState, mapDispatch)(FormModal);
