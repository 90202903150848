import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { DialogBody, DialogStep, MultistepDialog } from '@blueprintjs/core';
import { Button, Alert, Col, notification, Row, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  IMapUpdateWorkItems,
  MapReducerUpdate,
  updateMapState,
  updateWorkItems,
} from '@netomnia/modules/ProjectModule/views/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/views/Map/store/reducer';
import { initializeRecordForm } from '../../../../../../../redux/stores/form/actions';
import {
  createRecordsRequest,
  ICreateRecords,
  getRecordByIdRequest,
  IGetRecordById,
} from '../../../../../../../redux/stores/records/actions';
import { ISchemaReducer } from '../../../../../../../redux/stores/schemas/reducer';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { FEATURE_NAMES } from '../../constants';
import { allowedFeatureTypesForLinkage } from '../../MapSideBar/constants';
import '../styles.scss';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../../../redux/stores/userInterface/actions';
import CoreForm from '@core/components/Forms/CoreForm';
import { httpGet } from '@core/http/requests';
import { IFormReducer } from '@redux/stores/form/reducer';
import { isMobile } from 'react-device-detect';
import DataTable from 'src/core/components/AssociationDataTable';

const { PROJECT_MODULE, SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { FEATURE, FILE } = SchemaModuleEntityTypeEnums;

interface Props {
  schemaReducer: ISchemaReducer;
  mapReducer: MapReducer;
  recordFormReducer: any;
  updateMap: (params: MapReducerUpdate) => {};
  initializeForm: any;
  getRecordById: Function;
  openDrawer: (params: IOpenRecordDrawer) => void;
  updateWI: (params: IMapUpdateWorkItems) => void;
  createRecord: (params: ICreateRecords, cb: any) => void;
}

interface State {
  showError: boolean;
  isDialogOpen: boolean;
  isNextDisabled: boolean;
  isCreatingRecord: boolean;
  dialogState: {
    stepOne: {
      formData: any;
    };
  };
}

class AddPointFlowV2 extends React.Component<Props, State> {
  private featureSchema: any = undefined;
  private schemaActions: any[] = [];
  private actionName: string = '';
  private schemaAction: any = undefined;
  private formUUID: string = uuidv4();
  private recordType: string = '';
  private record: DbRecordEntityTransform | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      showError: false,
      isDialogOpen: false,
      isNextDisabled: true,
      isCreatingRecord: false,
      dialogState: {
        stepOne: {
          formData: undefined,
        },
      },
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { mapReducer } = this.props;

    if (!prevProps.mapReducer.addPointFlow && mapReducer.addPointFlow) {
      this.openDialog();
    }
  }

  openDialog = async () => {
    const { mapReducer } = this.props;

    if (!this.featureSchema) {
      await this.setFeatureSchema();
    }

    if (this.schemaActions.length === 0) {
      await this.setSchemaActions();
    }

    if (allowedFeatureTypesForLinkage.includes(mapReducer.createLinked?.fromType!)) {
      this.recordType = 'BLOCKAGE';
    } else if (mapReducer.isCreatingRFC) {
      this.recordType = 'SURVEY_STRUCTURE';
    } else if (mapReducer.isAddingHazard) {
      this.recordType = 'HAZARD';
    }

    if (this.recordType) {
      await this.setSchemaActionByType(this.recordType);
      this.setState({ isDialogOpen: true });
      this.setupStep('step-one');
    }
  };

  setupStep(newStep: string) {
    switch (newStep) {
      case 'step-one':
        this.setState({ isNextDisabled: true });

        this.props.initializeForm({
          hideRecordTypeField: true,
          formUUID: this.formUUID,
          schemaActionId: this.schemaAction.id,
          showFormModal: false,
          isCreateReq: true,
          schema: this.featureSchema,
          recordType: this.recordType,
          disabledFields: this.disabledFormFields(),
          additionalAssociations: this.getAssociationsForRecordCreate(),
          modified: [
            {
              schemaId: this.featureSchema.id,
              properties: {
                ExternalRef: this.formUUID,
                Coordinates: this.props.mapReducer.coordinates || undefined,
                RopeId:
                  this.props.mapReducer.createLinked?.fromType === FEATURE_NAMES.ROPE &&
                  this.props.mapReducer.createLinked.toType === 'blockage'
                    ? this.props.mapReducer.createLinked.fromId
                    : '',
                ChamberId:
                  this.props.mapReducer.createLinked?.fromType === FEATURE_NAMES.CHAMBER &&
                  this.props.mapReducer.createLinked.toType === 'blockage'
                    ? this.props.mapReducer.createLinked.fromId
                    : '',
                CableId:
                  this.props.mapReducer.createLinked?.fromType === FEATURE_NAMES.CABLE &&
                  this.props.mapReducer.createLinked.toType === 'blockage'
                    ? this.props.mapReducer.createLinked.fromId
                    : '',
                ClosureId:
                  this.props.mapReducer.createLinked?.fromType === FEATURE_NAMES.CLOSURE &&
                  this.props.mapReducer.createLinked.toType === 'blockage'
                    ? this.props.mapReducer.createLinked.fromId
                    : '',
                PoleId:
                  this.props.mapReducer.createLinked?.fromType === FEATURE_NAMES.POLE &&
                  this.props.mapReducer.createLinked.toType === 'blockage'
                    ? this.props.mapReducer.createLinked.fromId
                    : '',
                DuctId:
                  this.props.mapReducer.createLinked?.fromType === FEATURE_NAMES.DUCT &&
                  this.props.mapReducer.createLinked.toType === 'blockage'
                    ? this.props.mapReducer.createLinked.fromId
                    : '',
              },
            },
          ],
          sections: [
            {
              name: this.featureSchema.name,
              schema: this.featureSchema,
            },
          ],
        });
        break;
      case 'step-two':
        this.handleFeatureCreate();

        break;
      default:
        break;
    }
  }

  disabledFormFields = () => {
    const { mapReducer } = this.props;
    const linkedIdFields = ['ChamberId', 'RopeId', 'CableId', 'PoleId', 'ClosureId', 'DuctId'];

    if (mapReducer?.createLinked?.fromType) {
      return linkedIdFields.filter(
        (field: any) => field.toLowerCase().indexOf(mapReducer?.createLinked?.fromType) === -1,
      );
    } else {
      return [];
    }
  };

  setFeatureSchema = async () => {
    const response = await getOdinSchemaByEntity(PROJECT_MODULE, FEATURE);
    this.featureSchema = response;
  };

  setSchemaActions = async () => {
    const response = await httpGet(
      `IdentityModule/v1.0/schemas-actions/schema/${this.featureSchema.id}`,
    );

    this.schemaActions = response.data.data;
  };

  setSchemaActionByType = async (feature_type: string) => {
    switch (feature_type) {
      case 'BLOCKAGE':
        this.actionName = 'CreateBlockage';
        break;
      case 'HAZARD':
        this.actionName = 'CreateHazard';
        break;
      case 'SURVEY_STRUCTURE':
        this.actionName = 'CreateStructureRFC';
        break;
    }

    this.schemaAction = this.schemaActions.find((action: any) => action.name === this.actionName);
  };

  openNotification = () => {
    notification.open({
      message: `${this.deriveTypeFromActionName()} Created`,
      type: 'success',
      duration: 5,
      description: (
        <Row>
          <Col span={24}>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() =>
                this.record &&
                this.props.openDrawer({
                  recordId: this.record.id,
                  moduleName: PROJECT_MODULE,
                  entityName: FEATURE,
                })
              }
            >
              Quick View
            </Button>
          </Col>
        </Row>
      ),
    });
  };

  handleClose = () => {
    this.props.updateMap({
      addPointFlow: false,
    });

    this.setState({
      isDialogOpen: false,
      isNextDisabled: true,
      isCreatingRecord: false,
      dialogState: {
        stepOne: {
          formData: undefined,
        },
      },
    });
  };

  handleFeatureCreate = async () => {
    this.setState({ isCreatingRecord: true });
    const formData = this.props.recordFormReducer.modified[0];

    this.props.createRecord(
      {
        schema: this.featureSchema,
        createUpdate: [formData],
      },
      async (res: any) => {
        // TODO: Once createRecord is moved to use the V2 endpoint, use returnFullRecordOnCreate=true and remove the 2nd API call to get the record properties via getRecordById
        if (res?.id) {
          await this.props.getRecordById(
            { schema: this.featureSchema, recordId: res.id },
            (res: any) => {
              this.record = res;

              this.setState({ isCreatingRecord: false });
            },
          );
        } else {
          this.setState({ isCreatingRecord: false });
          this.handleClose();
        }
      },
    );
  };

  zoomInOnMapAfterConfirmation = () => {
    this.openNotification();

    this.props.getRecordById(
      { schema: this.featureSchema, recordId: this.record?.id },
      (res: any) => {
        if (res && getProperty(res, 'ExternalRef')) {
          this.props.updateMap({
            queryLayer: res.type.toLowerCase(),
            query: `type=${res.type.toLowerCase()}&featureId=${getProperty(res, 'ExternalRef')}`,
          });
        }
      },
    );

    // Reset map reducer
    this.props.updateMap({
      isCreatingRFC: false,
      creatingRFCFromIds: [],
      addEnabled: false,
      coordinates: [],
      createLinked: {
        fromType: undefined,
        fromId: undefined,
        toType: undefined,
      },
    });

    // Reset any remaining associations
    this.props.updateWI({
      creatingBlockageFromId: undefined,
    });

    this.handleClose();
  };

  getAssociationsForRecordCreate = () => {
    const { mapReducer } = this.props;
    const { workItems } = mapReducer;

    type association = {
      entity: string;
      recordId: string;
      relationType?: any;
    };

    let associations: association[] = [];

    // When Creating RFC From Ids
    if (mapReducer.isCreatingRFC && mapReducer.creatingRFCFromIds.length > 0) {
      mapReducer.creatingRFCFromIds.map((id: string) => {
        associations.push({
          entity: `${PROJECT_MODULE}:${FEATURE}`,
          recordId: id,
          relationType: RelationTypeEnum.CHILD,
        });
      });
    }

    // If Blockage is being created from Work Item
    if (workItems.creatingBlockageFromId) {
      associations.push({
        entity: `${PROJECT_MODULE}:${FEATURE}`,
        recordId: workItems.creatingBlockageFromId,
        relationType: RelationTypeEnum.CHILD,
      });
    }

    // If there is a WorkList in the mapReducer
    if (workItems.selectedWorkList) {
      associations.push({
        entity: `${PROJECT_MODULE}:WorkList`,
        recordId: workItems.selectedWorkList?.id,
      });
    }

    // If there is a Project in the mapReducer
    if (workItems.selectedProject) {
      associations.push({
        entity: `${PROJECT_MODULE}:Project`,
        recordId: workItems.selectedProject?.id,
      });
    }

    return associations;
  };

  deriveTypeFromActionName = () => {
    switch (this.actionName) {
      case 'CreateBlockage':
        return 'Blockage';
      case 'CreateHazard':
        return 'Hazard';
      case 'CreateStructureRFC':
        return 'Structure RFC';
    }
  };

  render() {
    const { isDialogOpen, isNextDisabled, isCreatingRecord } = this.state;

    return (
      <>
        <MultistepDialog
          style={{ minWidth: isMobile ? '100%' : '800px' }}
          resetOnClose
          isOpen={isDialogOpen}
          canOutsideClickClose={false}
          showCloseButtonInFooter={false}
          icon="info-sign"
          navigationPosition="top"
          onClose={this.handleClose}
          onChange={(newStep: string) => {
            this.setupStep(newStep);
          }}
          backButtonProps={{
            style: {
              display: 'none',
            },
          }}
          nextButtonProps={{
            disabled: isNextDisabled || isCreatingRecord,
          }}
          finalButtonProps={{
            text: 'Finish',
            onClick: () => this.zoomInOnMapAfterConfirmation(),
          }}
          title={`Create ${this.deriveTypeFromActionName()}`}
        >
          <DialogStep
            id="step-one"
            title="Create"
            nextButtonProps={{
              disabled: isNextDisabled || isCreatingRecord,
            }}
            panel={
              <DialogBody useOverflowScrollContainer>
                <CoreForm
                  type="EMBEDDED"
                  formUUID={this.formUUID}
                  isCreateRecord={true}
                  showFormActions={false}
                  isNextDisabled={(isNextDisabled: boolean) => {
                    this.setState({ isNextDisabled });
                  }}
                />
              </DialogBody>
            }
          />
          <DialogStep
            id="step-two"
            title="Upload"
            panel={
              <DialogBody useOverflowScrollContainer>
                {isCreatingRecord ? (
                  <Row style={{ textAlign: 'center', padding: '24px' }}>
                    <Col span={24}>
                      <Spin style={{ marginBottom: 12 }} />
                      <br />
                      <span>Creating record...</span>
                    </Col>
                  </Row>
                ) : (
                  this.record && (
                    <DataTable
                      collapsedByDefault
                      thumbnailSize={6}
                      record={this.record!}
                      moduleName={SCHEMA_MODULE}
                      entityName={FILE}
                      location="association"
                      displayType="thumbnails"
                      showFileCategoryForType="DEFAULT"
                    />
                  )
                )}
              </DialogBody>
            }
          />
        </MultistepDialog>

        {this.state.showError && (
          <Alert
            style={{ zIndex: 100, width: '100%' }}
            message="Cannot create feature"
            description="Coordinates not found, please re-draw feature on map."
            type="error"
            showIcon
            onClose={() => {
              this.setState({
                showError: false,
              });
            }}
          />
        )}
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  mapReducer: state.mapReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateWI: (params: IMapUpdateWorkItems) => dispatch(updateWorkItems(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  initializeForm: (params: IFormReducer) => dispatch(initializeRecordForm(params)),
  getRecordById: (params: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(params, cb)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(AddPointFlowV2);
