import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import { DownloadRecordPdf } from '@core/components/DownloadRecordPdf';
import FileDetailView from '@core/modules/FileModule/views/FileDetailView';
import UserDetailView from '@core/modules/IdentityModule/views/UserDetailView';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { FC, useContext } from 'react';
import DefaultDetailView from 'src/core/views/DefaultDetailView';

const { FILE } = SchemaModuleEntityTypeEnums;
const USER = 'User';

const PaymentGatewayDetailViewRouter: FC = () => {
  const { schema } = useContext(DetailViewContext);

  const detailViewController = () => {
    if (schema) {
      const { entityName } = schema;

      switch (entityName) {
        // SCHEMA MODULE
        case FILE:
          return <FileDetailView />;
        // IDENTITY MODULE
        case USER:
          return <UserDetailView />;
        // DEFAULT DETAIL VIEW
        default:
          return <DefaultDetailView showCollaborators headerExtras={[<DownloadRecordPdf />]} />;
      }
    } else return <></>;
  };

  return detailViewController();
};
export default PaymentGatewayDetailViewRouter;
