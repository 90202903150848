import React from 'react';
import './styles.scss';

interface ExternalLinkConfirmationModalProps {
	domain: string;
	onConfirm: () => void;
	onCancel: () => void;
	message?: string;
}

const ExternalLinkConfirmationModal: React.FC<ExternalLinkConfirmationModalProps> = ({
	                                                             domain,
	                                                             onConfirm,
	                                                             onCancel,
	                                                             message,
                                                             }) => {
	return (
		<div className="confirmation-modal-overlay">
			<div className="confirmation-modal">
				<h2>External Link Warning</h2>
				<div className="message">
					{message || 'Are you sure you want to open external link to'}{' '}
					<span className="domain">{domain}</span> in a new tab?
				</div>
				<div className="buttons">
					<button className="confirm" onClick={onConfirm}>
						Yes, open link
					</button>
					<button className="cancel" onClick={onCancel}>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

export default ExternalLinkConfirmationModal;
