import {
  isNetomniaUser,
  isPaymentGatewayUser,
  isYouFibreUser,
} from '@core/helpers/identityHelpers';
import DefaultDetailViewRouter from '@core/routes/DefaultDetailViewRouter';
import DefaultRoutes from '@core/routes/DefaultRoutes';
import NetomniaDetailViewRouter from '@netomnia/routes/NetomniaDetailViewRouter';
import NetomniaRoutes from '@netomnia/routes/NetomniaRoutes';
import YoufibreDetailViewRouter from '@youfibre/routes/YoufibreDetailViewRouter';
import YoufibreRoutes from '@youfibre/routes/YoufibreRoutes';
import { ReactNode } from 'react';
import PaymentGatewayDetailViewRouter from 'src/com.paymentgateway/routes/PaymentGatewayDetailViewRouter';
import PaymentGatewayRoutes from 'src/com.paymentgateway/routes/PaymentGatewayRoutes';

export type TRouteConfig = {
  routes: ReactNode[];
  detailViewRouter: ReactNode;
};

export const configureRouter = (): TRouteConfig => {
  let config: TRouteConfig = {
    routes: DefaultRoutes(),
    detailViewRouter: <DefaultDetailViewRouter />,
  };

  const netomniaUser = isNetomniaUser();
  const youfibreUser = isYouFibreUser();
  const paymentGatewayUser = isPaymentGatewayUser();

  if (netomniaUser) {
    config = {
      routes: NetomniaRoutes(),
      detailViewRouter: <NetomniaDetailViewRouter />,
    };
  }

  if (youfibreUser) {
    config = {
      routes: YoufibreRoutes(),
      detailViewRouter: <YoufibreDetailViewRouter />,
    };
  }

  if (paymentGatewayUser) {
    config = {
      routes: PaymentGatewayRoutes(),
      detailViewRouter: <PaymentGatewayDetailViewRouter />,
    };
  }

  return config;
};
