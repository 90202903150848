import { toSentenceCase } from '@core/helpers/stringHelpers';
import { httpGet } from '@core/http/requests';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';
import MyCaseAttachmentList from '../MyCaseAttachmentList';
import ExternalLinkConfirmationModal from '../ExternalLinkConfirmationModal';
import './styles.scss';

dayjs.extend(relativeTime);

interface Props {
  email: DbRecordEntityTransform;
}

/**
 * Decodes HTML entities in a string.
 */
function decodeEntities(text: string): string {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
}

/**
 * Removes invalid Unicode replacement characters from a string.
 */
function removeInvalidCharacters(str: string): string {
  return str.replace(/\uFFFD/g, '');
}

/**
 * Replaces patterns like [Some Text]<https://...>
 * with a proper anchor tag.
 */
function replaceBracketedAnchors(str: string): string {
  return str.replace(
      /\[([^\]]+)\]\s*<((?:https?:\/\/)[^>]+)>/gi,
      (match, linkText, url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${linkText}</a>`;
      }
  );
}

/**
 * Replaces patterns like <https://...>[Some Text]
 * (allowing whitespace or <br> tags between)
 * with a proper anchor tag.
 */
function replaceReversedBracketedAnchors(str: string): string {
  return str.replace(
      /((?:^|\s))<((?:https?:\/\/)[^\s>]+)>(?:\s|<br\s*\/?>)*\[([^\]]+)\]/gi,
      (match, leading, url, linkText) => {
        return `${leading}<a href="${url}" target="_blank" rel="noopener noreferrer">${linkText}</a>`;
      }
  );
}

/**
 * Replaces inline anchor patterns like "GitHub<https://...>"
 * with an anchor wrapping the captured text.
 * (This is the simpler version that simply wraps the text.)
 */
function replaceInlineAnchorTags(str: string): string {
  return str.replace(
      /(\b[\w.-]+)<((?:https?:\/\/)[^>]+)>/gi,
      (match, anchorText, url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${anchorText}</a>`;
      }
  );
}

/**
 * Replaces patterns like "image.png<https://...>" with a placeholder.
 */
function storeEmbeddedImages(str: string): string {
  return str.replace(
      /(\S+\.(?:png|jpg|jpeg|gif))\s*<((?:https?:\/\/)[^>]+)>/gi,
      (match, rawFilename, url) => {
        const filename = rawFilename; // add cleaning here if needed
        const markup = `
        <div class="embedded-image-wrapper">
          <button class="toggle-embedded-image-btn">
            <i class="bi bi-image"></i> Show ${filename}
          </button>
          <div class="embedded-image">
            <img src="${url}" alt="Embedded Image ${filename}" />
          </div>
        </div>
      `;
        return `<<<EMBEDDED_IMAGE_START>>>${encodeURIComponent(markup)}<<<EMBEDDED_IMAGE_END>>>`;
      }
  );
}

/**
 * Replaces all normal URLs (outside placeholders) with clickable <a> links.
 */
function replaceLinksOutsidePlaceholders(str: string): string {
  // Use a temporary placeholder for existing anchor tags to protect them from being modified
  const anchorPlaceholders: string[] = [];
  const htmlTagRegex = /<a\b[^>]*>.*?<\/a>|<img\b[^>]*\/?>/gi;

  // First, extract all existing anchor and image tags and replace them with placeholders
  const textWithPlaceholders = str.replace(htmlTagRegex, (match) => {
    anchorPlaceholders.push(match);
    return `@@HTML_TAG_${anchorPlaceholders.length - 1}@@`;
  });

  // Now, safely replace URLs only outside HTML tags
  const textWithLinks = textWithPlaceholders.replace(
      /\b(?:https?:\/\/|www\.)[^\s<>"']+/gi,
      (url) => {
        const href = url.startsWith('www.') ? `http://${url}` : url;
        const displayUrl = url.length > 150 ? `${url.slice(0, 50)} ... ${url.slice(-47)}` : url;
        return `<a href="${href}" target="_blank" rel="noopener noreferrer">${displayUrl}</a>`;
      }
  );

  // Finally, restore the original HTML anchor/image tags from placeholders
  return textWithLinks.replace(/@@HTML_TAG_(\d+)@@/g, (_, idx) => anchorPlaceholders[parseInt(idx)]);
}

/**
 * Restores the placeholders back into real embedded-image markup.
 */
function restoreEmbeddedImages(str: string): string {
  return str.replace(
      /<<<EMBEDDED_IMAGE_START>>>(.*?)<<<EMBEDDED_IMAGE_END>>>/g,
      (_, encoded) => decodeURIComponent(encoded)
  );
}

/**
 * Replaces CID tags with inline image markup. The image is rendered as a clickable thumbnail.
 */
/**
 * STEP: Replace all occurrences of CID tags (including SVGs) with inline image markup.
 * The CID image will render as a clickable thumbnail. SVGs are also handled.
 */
function replaceCidTagsWithInlineImages(
    htmlText: string,
    cidMap: Record<string, string>
): string {
  return htmlText.replace(/\[?cid:([^"\]'\s>]+)\]?/gi, (match, cid) => {
    let url = cidMap[cid];

    if (!url) {
      const baseCid = cid.split('@')[0];
      url = cidMap[baseCid];
    }

    if (!url) {
      return `
        <div class="cid-image-wrapper">
          <button class="toggle-cid-image-btn">
            Image not available: ${cid}
          </button>
        </div>
      `;
    }

    return `
      <div class="cid-image-wrapper">
        <button class="toggle-cid-image-btn">
          Toggle Show Inline-Image
        </button>
        <div class="cid-image">
          <a href="${url}" target="_blank" rel="noopener noreferrer">
            <img src="${url}" alt="Embedded Image" class="cid-thumbnail" />
          </a>
        </div>
      </div>
    `;
  });
}

/**
 * Legacy processing: minimal changes, only decode and convert newlines.
 */
function processLegacyMessage(rawMessage: string): string {
  const decoded = decodeEntities(rawMessage);
  const cleaned = removeInvalidCharacters(decoded);
  let html = cleaned.replace(/\r\n|\r|\n/g, '<br />');
  return DOMPurify.sanitize(replaceLinksWithATags(html), {
    ALLOWED_ATTR: ['target'],
  });
}

/**
 * Legacy helper: Replace plain URLs with anchors.
 */
function replaceLinksWithATags(str: string): string {
  return str.replace(/\b(?:https?:\/\/|www\.)[^\s<>\"]+\b/g, (url) => {
    const href = url.startsWith('www.') ? `http://${url}` : url;
    const displayUrl = url.length > 150 ? `${url.slice(0, 50)} ... ${url.slice(-47)}` : url;
    return `<a href="${href}" target="_blank" rel="noopener noreferrer">${displayUrl}</a>`;
  });
}

const MyCasesEmail: React.FC<Props> = ({ email }) => {
  const [sanitizedMessage, setSanitizedMessage] = useState('');
  const [legacyMode, setLegacyMode] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [pendingLink, setPendingLink] = useState('');
  const [pendingDomain, setPendingDomain] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);

  const message = getProperty(email, 'Message') || '';
  const fileIds = getProperty(email, 'Files') || '';
  const senderType = getProperty(email, 'SenderType');

  useEffect(() => {
    const processMessage = async () => {
      try {
        if (legacyMode) {
          setSanitizedMessage(processLegacyMessage(message));
        } else {
          const cidMap: Record<string, string> = {};

          if (fileIds.trim()) {
            const { data } = await httpGet(`SchemaModule/v1.0/db/File/many?ids=${fileIds}`);
            data?.data?.forEach((file: DbRecordEntityTransform) => {
              if (file.title && file.properties?.Url) {
                cidMap[file.title] = file.properties.Url;
              }
            });
          }

          let html = decodeEntities(message);
          html = removeInvalidCharacters(html).replace(/\r\n|\r|\n/g, '<br />');
          html = replaceBracketedAnchors(html);
          html = replaceReversedBracketedAnchors(html);
          html = replaceInlineAnchorTags(html);
          html = restoreEmbeddedImages(replaceLinksOutsidePlaceholders(storeEmbeddedImages(html)));
          html = replaceCidTagsWithInlineImages(html, cidMap);

          setSanitizedMessage(DOMPurify.sanitize(html, {
            ALLOWED_TAGS: ['div', 'img', 'br', 'a', 'button', 'p', 'span', 'i'],
            ALLOWED_ATTR: ['href', 'src', 'alt', 'target', 'rel', 'style', 'class'],
          }));
        }
      } catch (error) {
        console.error(error);
      }
    };

    void processMessage();
  }, [fileIds, message, legacyMode, email]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleToggle = (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.matches('.toggle-cid-image-btn, .toggle-embedded-image-btn')) {
        target.nextElementSibling?.classList.toggle('active');
      }
    };

    container.addEventListener('click', handleToggle);
    return () => container.removeEventListener('click', handleToggle);
  }, [sanitizedMessage]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleAnchorClick = (e: Event) => {
      const anchor = (e.target as HTMLElement).closest('a');
      if (anchor) {
        e.preventDefault();
        setPendingLink(anchor.href);
        setPendingDomain(new URL(anchor.href).hostname);
        setModalVisible(true);
      }
    };

    container.addEventListener('click', handleAnchorClick);
    return () => container.removeEventListener('click', handleAnchorClick);
  }, [sanitizedMessage]);

  return (
      <>
        <div className="display-toggle-container">
          <button
              className="display-toggle-button"
              onClick={() => setLegacyMode((prev) => !prev)}
          >
            {legacyMode ? 'Switch to Advanced Display' : 'Switch to Legacy Display'}
          </button>
        </div>

        <Row justify="end" style={{ padding: '5px 20px 5px 0' }}>
          <Col span={24} ref={containerRef} className={`myCasesUsersEmail ${senderType === 'CUSTOMER' ? 'customerEmail' : ''}`}>
            <Row>
              <Col span={24}>
                <i className={'bi bi-envelope'} style={{ marginRight: 5 }} />
                <span style={{ fontWeight: 500 }}>{senderType} Email</span>
              </Col>

              <Col span={14} style={{ marginTop: 5 }}>
                <span style={{ fontSize: '0.8em', fontWeight: 600 }}>{getProperty(email, 'From')}</span>
              </Col>
              <Col span={10} style={{ textAlign: 'right', marginTop: 5 }}>
              <span style={{ fontSize: '0.8em', color: '#545A5D' }}>
                {dayjs(email.createdAt).format('ddd, MMM DD, HH:mm')} ({dayjs(email.createdAt).fromNow()})
              </span>
              </Col>

              <Col span={24}>
                <span style={{ fontSize: '0.8em', color: '#545A5D' }}>To: {getProperty(email, 'To')}</span>
                {getProperty(email, 'Cc') && (
                    <span style={{ fontSize: '0.8em', color: '#545A5D' }}>, Cc: {getProperty(email, 'Cc')}</span>
                )}
              </Col>

              <Col span={24} style={{ marginTop: 10 }}>
                <span style={{ fontWeight: 600 }}>{getProperty(email, 'Subject')}</span>
                <div style={{ marginTop: 5, overflowWrap: 'break-word' }}>
                  <div dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
                </div>
              </Col>

              {fileIds && (
                  <Col span={24} style={{ marginTop: 15, marginBottom: 5 }}>
                    <MyCaseAttachmentList fileIds={fileIds} thumbSize={60} justify="start" />
                  </Col>
              )}
            </Row>
          </Col>
        </Row>

        {modalVisible && (
            <ExternalLinkConfirmationModal
                domain={pendingDomain}
                onConfirm={() => window.open(pendingLink, '_blank')}
                onCancel={() => setModalVisible(false)}
                message="Are you sure you want to open external link to"
            />
        )}
      </>
  );
};

export default MyCasesEmail;
