import { Button } from '@blueprintjs/core';
import OdinTable from '@core/components/OdinTable';
import TableCellStageTag from '@core/components/TableCellStageTag';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { IOpenRecordDrawer, openRecordDrawer } from '@redux/stores/userInterface/actions';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
interface Props {
  workOrders: DbRecordEntityTransform[];
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const ContactAccountWorkOrderTable: React.FC<Props> = (props: Props) => {
  const { workOrders, openDrawer } = props;

  const tableWorkOrders = workOrders.map((wo: DbRecordEntityTransform) => ({
    key: wo.id,
    recordNumber: (
      <Link key={wo.id} to={getBrowserPath(wo)} target="_blank">
        {wo.recordNumber}
      </Link>
    ),
    title: (
      <div key={`${wo.id}-titleDiv`}>
        <span key={`${wo.id}-title`} style={{ fontWeight: 500 }}>
          {wo.title}
        </span>
        <br key={`${wo.id}-br`} />
        <span key={`${wo.id}-createdAt`} style={{ fontSize: '0.7em', opacity: 0.7 }}>
          Created: {dayjs(wo.createdAt).format('MM/DD/YYYY HH:mm')}
        </span>
      </div>
    ),
    status: <TableCellStageTag small key={wo.id} record={wo} />,
    action: (
      <Button
        key={wo.id}
        size="small"
        icon="panel-stats"
        onClick={() =>
          openDrawer({
            recordId: wo?.id || '',
            moduleName: 'FieldServiceModule',
            entityName: 'WorkOrder',
          })
        }
      />
    ),
  }));

  return (
    <Row>
      <Col span={24}>
        <OdinTable
          height="auto"
          data={tableWorkOrders}
          columns={[
            {
              key: 'recordNumber',
              title: 'WO #',
              width: 3,
            },
            {
              key: 'title',
              title: 'Title',
              width: 16,
            },
            {
              key: 'status',
              title: 'Status',
              width: 4,
            },
            {
              key: 'action',
              title: '',
              width: 1,
              align: 'right',
            },
          ]}
        />
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(ContactAccountWorkOrderTable);
