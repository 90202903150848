import { Button } from '@blueprintjs/core';
import OdinTable from '@core/components/OdinTable';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { IOpenRecordDrawer, openRecordDrawer } from '@redux/stores/userInterface/actions';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

interface Props {
  paymentMethods: DbRecordEntityTransform[];
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const ContactAccountPaymentMethodTable: React.FC<Props> = (props: Props) => {
  const { paymentMethods, openDrawer } = props;

  const tablePaymentMethods = paymentMethods.map((pm: DbRecordEntityTransform) => ({
    key: pm.id,
    title: (
      <div key={`${pm.id}-titleDiv`}>
        <Link key={pm.id} to={getBrowserPath(pm)} target="_blank" style={{ fontWeight: 500 }}>
          {pm.title}
        </Link>
        <br />
        <span key={`${pm.id}-createdAt`} style={{ fontSize: '0.7em', opacity: 0.7 }}>
          Created: {dayjs(pm.createdAt).format('MM/DD/YYYY HH:mm')}
        </span>
      </div>
    ),
    status: getProperty(pm, 'Status'),
    action: (
      <Button
        key={pm.id}
        onClick={() =>
          openDrawer({
            recordId: pm?.id || '',
            moduleName: 'BillingModule',
            entityName: 'PaymentMethod',
          })
        }
        size="small"
        icon="panel-stats"
      />
    ),
  }));

  return (
    <Row>
      <Col span={24}>
        <OdinTable
          showEmpty
          height="auto"
          emptyDescription="No Payment Methods found"
          data={tablePaymentMethods}
          columns={[
            {
              key: 'title',
              title: 'Title',
              width: 14,
            },
            {
              key: 'status',
              title: 'Status',
              align: 'center',
              width: 8,
            },
            {
              key: 'action',
              title: '',
              width: 2,
              align: 'right',
            },
          ]}
        />
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(ContactAccountPaymentMethodTable);
